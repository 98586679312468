import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-omaha-nebraska.png'
import image0 from "../../images/cities/scale-model-of-meadowlark-theater-in-omaha-nebraska.png"
import image1 from "../../images/cities/scale-model-of-stingray-beach-in-omaha-nebraska.png"
import image2 from "../../images/cities/scale-model-of-omaha's-henry-doorly-zoo-and-aquarium-in-omaha-nebraska.png"
import image3 from "../../images/cities/scale-model-of-desert-dome-&-kingdoms-of-the-night-in-omaha-nebraska.png"
import image4 from "../../images/cities/scale-model-of-the-durham-museum-in-omaha-nebraska.png"
import image5 from "../../images/cities/scale-model-of-el-museo-latino-in-omaha-nebraska.png"
import image6 from "../../images/cities/scale-model-of-omaha-visitors-center-in-omaha-nebraska.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Omaha'
            state='Nebraska'
            image={image}
            lat='41.2565369'
            lon='-95.93450339999998'
            attractions={ [{"name": "Meadowlark Theater", "vicinity": "3701 S 10th St, Omaha", "types": ["point_of_interest", "establishment"], "lat": 41.22781980000001, "lng": -95.9285681}, {"name": "Stingray Beach", "vicinity": "3701 S 10th St, Omaha", "types": ["point_of_interest", "establishment"], "lat": 41.2286766, "lng": -95.92806289999999}, {"name": "Omaha's Henry Doorly Zoo and Aquarium", "vicinity": "3701 S 10th St, Omaha", "types": ["zoo", "park", "point_of_interest", "establishment"], "lat": 41.2251159, "lng": -95.9273508}, {"name": "Desert Dome & Kingdoms of the Night", "vicinity": "3701 S 10th St, Omaha", "types": ["zoo", "point_of_interest", "establishment"], "lat": 41.2256687, "lng": -95.92773290000002}, {"name": "The Durham Museum", "vicinity": "801 S 10th St, Omaha", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.251469, "lng": -95.92896489999998}, {"name": "El Museo Latino", "vicinity": "4701 S 25th St, Omaha", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.2118929, "lng": -95.94795019999998}, {"name": "Omaha Visitors Center", "vicinity": "1001 Farnam St, Omaha", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.2574164, "lng": -95.92947040000001}] }
            attractionImages={ {"Meadowlark Theater":image0,"Stingray Beach":image1,"Omaha's Henry Doorly Zoo and Aquarium":image2,"Desert Dome & Kingdoms of the Night":image3,"The Durham Museum":image4,"El Museo Latino":image5,"Omaha Visitors Center":image6,} }
       />);
  }
}